import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { withFirebase } from '../Firebase';
import TableIcons from '../../constants/tableIcons';
import { useTranslation } from 'react-i18next';
import MaterialTable from '../MaterialTable/MaterialTable';
import 'jspdf-autotable';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import { addDays, endOfMonth, format, startOfMonth } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../store/actions/toastAction';
import {
  checkIfPermissionIsGranted,
  downloadFileFromUrl,
  getSitesFromDatabase,
  isAuthUserAdmin,
  REGION_LOOKUP,
} from '../../utils/common';
import GenerateTestReportDialog from './dialogs/GenerateTestReportDialog';
import GenerateSoftwareReportDialog from './dialogs/GenerateSoftwareReportDialog';
import GenerateOfflineReportDialog from './dialogs/GenerateOfflineReportDialog';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import NoteIcon from '@mui/icons-material/Note';
import EvStationIcon from '@mui/icons-material/EvStation';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import ApiIcon from '@mui/icons-material/Api';
import { StyledMenu } from '../Common/NavDropDown';
import axios from 'axios';
import * as API from '../../constants/api';
import AdornedButton from '../Button/AdornedButton';
import { getDocs, query, where } from 'firebase/firestore';

const PREFIX = 'index';

const classes = {
  table: `${PREFIX}-table`,
  selectContainer: `${PREFIX}-selectContainer`,
  selectLabel: `${PREFIX}-selectLabel`,
  datePicker: `${PREFIX}-datePicker`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.table}`]: {
    margin: theme.spacing(2, 0),
  },

  [`& .${classes.selectContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },

  [`& .${classes.selectLabel}`]: {
    marginRight: '15px',
  },

  [`& .${classes.datePicker}`]: {
    flex: 1,
  },

  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    gap: '20px',
    marginBottom: '30px',
  },
}));

const ReportTable = ({ data, columns }) => {
  const { t } = useTranslation();
  return (
    <MaterialTable
      icons={TableIcons}
      title={t('monthly_reports')}
      columns={columns}
      data={data}
      options={{
        filtering: true,
      }}
    />
  );
};

const ReportPage = props => {
  const { t } = useTranslation();
  const [selectedDate, handleDateChange] = useState(new Date());
  const [showGenerateTestReport, setShowGenerateTestReport] = useState(false);
  const [showGenerateSoftwareReport, setShowGenerateSoftwareReport] = useState(false);
  const [showGenerateOfflineReport, setShowGenerateOfflineReport] = useState(false);
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const authUser = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCustomReportLoading, setIsCustomReportLoading] = useState(false);

  const getSites = async () => {
    const sites = (await getSitesFromDatabase(props.firebase, authUser)).map(site => {
      return {
        id: site.id,
        title: `${site.clientName} - ${site.name}`,
      };
    });
    setSites(sites);
  };

  const downloadPdf = site => {
    window.open(site.url, '_blank');
  };

  const handleActionButtonClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const showSoftwareNotesReport = () => {
    setAnchorEl(null);
    setShowGenerateSoftwareReport(true);
  };

  const showOfflineReport = () => {
    setAnchorEl(null);
    setShowGenerateOfflineReport(true);
  };

  const columns = [
    {
      title: t('region'),
      field: 'region',
      width: '60px',
      lookup: REGION_LOOKUP,
      render: rowData => <div>{rowData.region?.toUpperCase()}</div>,
    },
    {
      title: t('client'),
      field: 'clientName',
    },
    {
      title: t('site'),
      field: 'siteName',
    },
    {
      title: t('language'),
      field: 'language',
    },
    {
      title: t('date'),
      field: 'date',
      render: rowData => {
        return format(new Date(parseInt(rowData.date) - 24 * 60 * 60 * 1000), 'MMMM yyyy');
      },
    },
    {
      title: t('download'),
      render: rowData => (
        <Button variant="contained" startIcon={<DownloadIcon />} onClick={() => downloadPdf(rowData)}>
          {t('pdf_report')}
        </Button>
      ),
    },
  ];

  const handleClose = async event => {
    setAnchorEl(null);
    event.preventDefault();
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        if (isAuthUserAdmin(authUser)) {
          await getSites();
        }
        let reportQuery = props.firebase.reports();
        let reports = [];
        // Added 1 day because it takes about 10 hours to generate full monthly report and
        // generated report date can be plus 1
        const startDate = addDays(startOfMonth(selectedDate), 1).valueOf();
        const endDate = addDays(endOfMonth(selectedDate), 1).valueOf();
        // Multiple inequality operators can't be used together (`in` and `>` condition)
        // To achieve this, we need to use Firestore composite index but I don't think it's worth
        // Just need to get list of all reports for normal user and filter it after we get result
        if (isAuthUserAdmin(authUser)) {
          reportQuery = query(reportQuery, where('date', '>', startDate));
          reportQuery = query(reportQuery, where('date', '<', endDate));
          reports = (await getDocs(reportQuery)).docs.map(doc => doc.data());
        } else {
          const sitesSnapshot = await getDocs(
            query(
              props.firebase.sites(),
              where('administrators', 'array-contains', `${props.firebase.auth.currentUser.uid}`),
            ),
          );
          const siteIds = sitesSnapshot.docs.map(doc => doc.data().siteid);
          if (!siteIds.length) {
            setReports([]);
            setLoading(false);
            return;
          }
          reportQuery = query(reportQuery, where('siteid', 'in', siteIds));
          reports = (await getDocs(reportQuery)).docs
            .map(doc => doc.data())
            .filter(report => report.date > startDate && report.date < endDate);
        }
        setReports(reports);
        setLoading(false);
      } catch (error) {
        dispatch(showToast(`${error}`, 'error'));
        setLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const generateStationReport = async event => {
    setAnchorEl(null);
    event.preventDefault();
    try {
      setIsCustomReportLoading(true);
      const res = await axios.get(`${API.GENERATE_STATION_REPORT}`, { responseType: 'blob' });
      const blobURL = URL.createObjectURL(res.data);
      await downloadFileFromUrl(blobURL, 'station-report.pdf');
    } catch (e) {
      dispatch(showToast(`${e}`, 'error'));
    } finally {
      setIsCustomReportLoading(false);
    }
  };

  const generateInterfaceReport = async event => {
    setAnchorEl(null);
    event.preventDefault();
    try {
      setIsCustomReportLoading(true);
      const res = await axios.get(`${API.GENERATE_INTERFACE_REPORT}`, { responseType: 'blob' });
      const blobURL = URL.createObjectURL(res.data);
      await downloadFileFromUrl(blobURL, 'interface-report.pdf');
    } catch (e) {
      dispatch(showToast(`${e}`, 'error'));
    } finally {
      setIsCustomReportLoading(false);
    }
  };
  return (
    <StyledContainer maxWidth="xl">
      {checkIfPermissionIsGranted('showReport', authUser) && (
        <div className={classes.buttonContainer}>
          <Button variant="contained" color="primary" onClick={() => setShowGenerateTestReport(true)}>
            {t('generate_monthly_test_report')}
          </Button>
          <AdornedButton
            onClick={e => handleActionButtonClick(e)}
            endIcon={<KeyboardArrowDownIcon />}
            aria-controls={anchorEl ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? 'true' : undefined}
            variant="contained"
            color="primary"
            disableElevation
            aria-label="actions"
            disabled={isCustomReportLoading}
            loading={isCustomReportLoading}
          >
            {t('generate_custom_report')}
          </AdornedButton>
        </div>
      )}
      <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => showSoftwareNotesReport()} disableRipple>
          <NoteIcon />
          &nbsp;
          {t('software_notes_report')}
        </MenuItem>
        <MenuItem onClick={() => showOfflineReport()} disableRipple>
          <DesktopAccessDisabledIcon />
          &nbsp;
          {t('offline_report')}
        </MenuItem>
        <MenuItem onClick={generateStationReport} disableRipple>
          <EvStationIcon />
          &nbsp;
          {t('station_report')}
        </MenuItem>
        <MenuItem onClick={generateInterfaceReport} disableRipple>
          <ApiIcon />
          &nbsp;
          {t('interface_report')}
        </MenuItem>
      </StyledMenu>
      <GenerateTestReportDialog
        show={showGenerateTestReport}
        toggler={setShowGenerateTestReport}
        sites={sites}
        firebase={props.firebase}
      />
      <GenerateSoftwareReportDialog show={showGenerateSoftwareReport} toggler={setShowGenerateSoftwareReport} />
      <GenerateOfflineReportDialog show={showGenerateOfflineReport} toggler={setShowGenerateOfflineReport} />
      <div className={classes.selectContainer}>
        <span className={classes.selectLabel}>{t('select_report_month')}</span>
        <div className={classes.datePicker}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="month"
              views={['year', 'month']}
              label={t('month')}
              name="month"
              fullWidth
              onChange={handleDateChange}
              value={selectedDate}
              disabled={loading}
              renderInput={props => <TextField {...props} />}
            />
          </LocalizationProvider>
        </div>
      </div>
      {!loading ? (
        <div>
          <div className={classes.table}>
            <ReportTable columns={columns} data={reports} />
          </div>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </StyledContainer>
  );
};

export default withFirebase(ReportPage);
