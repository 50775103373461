import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import AdornedButton from '../../Button/AdornedButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import * as API from '../../../constants/api';
import { showToast } from '../../../store/actions/toastAction';
import { useDispatch } from 'react-redux';
import { downloadFileFromUrl } from '../../../utils/common';
import Regions from '../../../constants/regions.json';

const PREFIX = 'GenerateOfflineReportDialog';

const classes = {
  select: `${PREFIX}-select`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.select}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  }
}));

const GenerateOfflineReportDialog = ({ show, toggler }) => {
  const [selectedRegion, setSelectedRegion] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    toggler(false);
  };

  const generateReport = async event => {
    // const region = selectedRegions.map(region => region.code).join(',');
    event.preventDefault();
    try {
      setLoading(true);
      const res = await axios.get(`${API.GENERATE_OFFLINE_REPORT}?region=${selectedRegion?.code}`, { responseType: 'blob' });
      const blobURL = URL.createObjectURL(res.data);
      await downloadFileFromUrl(blobURL, 'offline-device-report.pdf');
      toggler(false);
    } catch (e) {
      dispatch(showToast(`${e}`, 'error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDialog open={show || false} onClose={onClose}>
      <DialogTitle>{t('generate_offline_report')}</DialogTitle>
      <form noValidate>
        <DialogContent>
          <Autocomplete
            id="region-select"
            options={Regions}
            getOptionLabel={option => option.name}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            onChange={(event, value) => setSelectedRegion(value)}
            value={selectedRegion}
            className={classes.select}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label={t('region')}
                placeholder={t('region')}
                name="dashboard"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <AdornedButton
            variant="contained"
            color="primary"
            onClick={generateReport}
            disabled={loading || !selectedRegion}
            loading={loading}
          >
            {t('generate_offline_report')}
          </AdornedButton>
          <AdornedButton variant="contained" onClick={onClose} disabled={loading}>
            {t('close')}
          </AdornedButton>
        </DialogActions>
      </form>
    </StyledDialog>
  );
};
export default GenerateOfflineReportDialog;
