export const SAVE_CONTACT = `save-contact`;
export const DELETE_CONTACT = `delete-contact`;
export const CREATE_USER = `create-user`;
export const DELETE_USER = `delete-user`;
export const GENERATE_PDF = `generate-pdf`;
export const UPDATE_USER = `update-user`;
export const TAKE_CONTROL = `take-control?`;
export const SEND_PASSWORD_RESET_EMAIL = `send-password-reset-email`;
export const SEND_FAILURE_CHECK = `send-test-failing-check`;
export const GENERATE_TEST_REPORT = `generate-test-monthly-report-for-site`;
export const GENERATE_SOFTWARE_REPORT = `generate-software-report`;
export const GENERATE_OFFLINE_REPORT = `generate-offline-report`;
export const GENERATE_STATION_REPORT = `generate-station-report`;
export const GENERATE_INTERFACE_REPORT = `generate-interface-report`;
export const GET_PULSE_ONLINE_DEVICES = `get-pulse-online-devices`;
export const UPDATE_SITE = `site`;
export const GET_FILTERED_LIST = `get-notification-eligible-devices?`;
export const GET_BACKUP_MANAGER_URL = `get-backup-manager-url`;
export const GET_COVE_BACKUP_DETAILS = `get-cove-backup-details`;
export const LOGIN = `login`;
export const SEND_TWO_FACTOR_CODE = `send-2fa-code`;
export const VALIDATE_CODE = `verify-2fa-code`;
export const RESEND_CODE = `resend-2fa-code?`;
export const START_TUNNEL = `start-tunnel`;
export const GET_DEVICES_FOR_SELECT = `get-devices-for-select`;
export const SEND_NOTIFICATION_TO_DEVICE = `send-notification-to-device`;
export const GET_ACTIVE_TUNNELS = `active-tunnels`;
