import { differenceInDays, format, parse, startOfDay } from 'date-fns';
import { getLast5Backups, isDeviceOnline, replaceError } from '../../utils/common';

export const DEVICE_EXPORT_COLUMNS = [
  'Client ID',
  'Site ID',
  'Device ID',
  'Region',
  'Client',
  'Site',
  'Name',
  'Last Response',
  'Serial Number',
  'Last 5 Backups',
  'CirrusPRO Database Size',
  'CPU',
  'RAM',
  'HDD',
  'Manufacturer',
  'Model',
  'OS',
  'State',
  'Messaging Agent',
  'Country',
  'City',
  'Software',
  'Last Boot Time',
  'Download speed',
  'Upload speed',
  'Warranty Status',
  'Warranty Check Status',
  'Warranty Check Date',
  'Warranty Start Date',
  'Warranty End Date',
  'RBCustom1',
  'RBCustom2',
  'GSP Expiry Date',
  'GSP Number',
  'Golf Registration Number',
  'CirrusPro Notes Count',
  'Tunnel URL',
  'Cove Selected Size In GB',
];

export const SOFTWARE_ASSET_EXPORT_COLUMNS = [
  'Client ID',
  'Site ID',
  'Device ID',
  'Region',
  'Client',
  'Site',
  'Name',
  'RainBird Software',
  'Software',
  'Version',
  'Install Date',
];

export const getLastResponseFormattedDate = (time, offset) => {
  const date = new Date(`${time} UTC`);
  if (isNaN(date) || typeof offset === 'object' || isNaN(Number(offset))) {
    return '';
  }
  // const updatedDate = addSeconds(date, -Number(offset));
  // return format(updatedDate, 'yyyy-MM-dd');
  return format(date, 'yyyy-MM-dd');
};

export const getLastResponse = (time, offset) => {
  const date = new Date(`${time} UTC`);
  if (isNaN(date) || typeof offset === 'object' || isNaN(Number(offset))) {
    return null;
  }
  return date;
  // return addSeconds(date, -Number(offset));
};

export const getDaysRemaining = (compareDate, oppositeFlag = false) => {
  if (!compareDate) {
    return undefined;
  }
  const curDate = startOfDay(new Date());
  const compareStartDate = startOfDay(compareDate);
  if (oppositeFlag) {
    return differenceInDays(curDate, compareStartDate);
  }
  return differenceInDays(compareStartDate, curDate);
};

export const getFormatDate = timestamp => {
  if (!timestamp) {
    return '';
  }
  return format(timestamp, 'yyyy-MM-dd');
};

export const parseDate = dateString => {
  if (!dateString) {
    return null;
  }
  return parse(dateString, 'yyyy-MM-dd', new Date());
};

export const getFormatDateTime = timestamp => {
  if (!timestamp) {
    return '';
  }
  return format(timestamp, 'yyyy-MM-dd hh:mm:ss');
};

export function displayExpiryInDays(expiry) {
  return expiry || expiry === 0 ? `(${expiry >= 0 ? '+' : ''}${expiry})` : '';
}

function encloseQuoteForCSV(value) {
  if (typeof value !== 'string') {
    return value;
  }
  // Replace multiple spaces with a single space. Use '  ' for double spaces if preferred.
  // Escape quotes by doubling them
  return value?.replace(/\s+/g, ' ');
}

export const getDeviceExportRowData = row => {
  return [
    row.clientid,
    row.siteid,
    row.serverid,
    row.region?.toUpperCase(),
    row.clientName,
    row.siteName,
    row.name,
    getLastResponseFormattedDate(row.utc_apt, row.utc_offset),
    row.device_serial,
    row.last_backups?.map(backup => backup.status).join(' | '),
    row.cirrusProDatabaseSize,
    row.cpu,
    `${(row.ram ?? 0) / 1024 / 1024} MB`,
    row.hdd,
    row.manufacturer,
    row.model,
    row.os,
    row.state,
    row.pulse,
    row.country,
    row.city,
    row.software,
    getFormatDateTime(row.last_boot_time * 1000),
    row.download_speed,
    row.upload_speed,
    row.warranty,
    row.warrantyCheck,
    getFormatDate(row.warrantyCheckDate),
    getFormatDate(row.warrantyStartDate),
    getFormatDate(row.warrantyEndDate),
    encloseQuoteForCSV(row.rbCustom1Check),
    encloseQuoteForCSV(row.rbCustom2Check),
    row.gsp_expiry,
    row.gsp_number,
    row.golf_registration_number,
    encloseQuoteForCSV(row.numberOfCirrusProNotes),
    row.ngrokDomain,
    row.coveSelectedSize,
  ];
};

export const getSoftwareAssetExportRowData = row => {
  let softwareAssets = [];
  try {
    softwareAssets = JSON.parse(row.softwareAssets);
  } catch (e) {
    console.log(`Error occurred while parsing software assets`);
  }

  if (softwareAssets.length === 0) {
    softwareAssets = [{}];
  }
  try {
    return softwareAssets.map(asset => {
      const installDate = new Date(asset.install_date);
      let installDateString = '';
      if (asset.install_date && !isNaN(installDate)) {
        installDateString = format(installDate, 'yyyy-MM-dd');
      }
      return [
        row.clientid,
        row.siteid,
        row.serverid,
        row.region?.toUpperCase(),
        row.clientName,
        row.siteName,
        row.name,
        row.software,
        asset.name,
        typeof asset.version === 'string' ? asset.version : '',
        installDateString,
      ];
    });
  } catch (e) {
    return [];
  }
};

const convertToGB = sizeString => {
  if (!sizeString) return null;

  const sizePattern = /(\d+\.?\d*)(MB|GB|Bytes)/;
  const match = sizePattern.exec(sizeString);

  if (!match) return null;

  const size = parseFloat(match[1]);
  const unit = match[2];

  let sizeInGB;
  switch (unit) {
    case 'GB':
      sizeInGB = size;
      break;
    case 'MB':
      sizeInGB = size / 1024;
      break;
    case 'Bytes':
      sizeInGB = size / (1024 * 1024 * 1024);
      break;
    default:
      return null;
  }

  return parseFloat(sizeInGB.toFixed(2)); // Convert to 2 decimal points and then back to number
};

const formatSizeInGB = sizeInKB => {
  if (!sizeInKB) {
    return;
  }
  // if (sizeInKB < 1024) {
  //   return sizeInKB.toFixed(2) + ' KB';
  // } else if (sizeInKB < 1024 * 1024) {
  //   return (sizeInKB / 1024).toFixed(2) + ' MB';
  // } else {
  // Just show as GB for now
  return (sizeInKB / (1024 * 1024)).toFixed(2);
  // }
};

export const mapDeviceData = (devices, failingChecks, pulseOnlineDevices, tunnels) => {
  let newDevices = devices.map(device => {
    if (typeof device['device_serial'] === 'object') {
      device['device_serial'] = '';
    }
    const backups = getLast5Backups(device.backup_history);
    let gspExpiry = parseDate(device.gsp_expiry);
    let warrantyExpiry = device.warrantyEndDate ? new Date(device.warrantyEndDate) : null;
    const lastResponse = getLastResponse(device.utc_apt, device.utc_offset);
    const tunnelData = tunnels.find(tunnel => tunnel.labels?.rmmid === device.serverid);
    return {
      ...device,
      software: replaceError(device.software),
      country: replaceError(device.country),
      city: replaceError(device.city),
      download_speed: replaceError(device.download_speed),
      upload_speed: replaceError(device.upload_speed),
      ngrokDomain: tunnelData?.labels?.subdomain
        ? `https://${tunnelData?.labels?.subdomain}.rainbirdconnect.com`
        : undefined,
      state: isDeviceOnline(device) ? 'Online' : 'Offline',
      pulse: pulseOnlineDevices.includes(device.serverid) ? 'Online' : 'Offline',
      last_backups: backups,
      tunnelStatus: tunnelData ? 'ENABLED' : 'DISABLED',
      deviceType: tunnelData?.labels?.deviceType ?? 'CirrusPro',
      tunnel: tunnelData,
      ngrokStatus: tunnelData ? 'green' : 'off',
      last_response: getFormatDate(lastResponse),
      last_response_in_days: getDaysRemaining(lastResponse, true),
      gsp_expiry: getFormatDate(gspExpiry),
      gsp_expiry_in_days: getDaysRemaining(gspExpiry),
      warranty_expiry: getFormatDate(warrantyExpiry),
      warranty_expiry_in_days: getDaysRemaining(warrantyExpiry),
      cirrus_pro_database_size_in_gb: convertToGB(device.cirrusProDatabaseSize),
      coveSelectedSize: formatSizeInGB(device.coveSelectedSizeInKB),
    };
  });
  newDevices = newDevices.filter(device => Boolean(device.region));
  return newDevices;
};
